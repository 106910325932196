<template>
  <div class="member">
    <AppHeader />
    <div id="member-content">
      <div id="content">
        <img :src="member.img" :alt="member.name" class="icon animate__animated ">
        <div class="profile animate__animated animate__fadeInRight" >
          <div class="names profile animate__animated animate__fadeInRight" :style="styles" >
            <h1 class="name" :style="styles">
              {{ member.name}}
            </h1>
            <span class="nameLine"></span>
            <span class="name_en" :style="styles"> {{ member.name_en }} </span>
          </div>
          <div class="profile">
            <ul>
              <li v-for="profile in profiles" :key="profile.id">
                {{ profile }}
              </li>
            </ul>
          </div>
          <p class="intro">
            {{ member.intro }}
          </p>
          <div class="sns">
            <i class="fab fa-twitter sns" @click="clickSNSLink(member.twitter)" style="color: #1DA1F2;" v-if="member.twitter != null"></i>
            <i class="fab fa-youtube sns" @click="clickSNSLink(member.youtube)" style="color: #DA1725;" v-if="member.youtube != null"></i>
            <i class="fab fa-twitch sns" @click="clickSNSLink(member.twitch)" style="color: #6441A4;" v-if="member.twitch != null"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AppHeader from '../components/AppHeader.vue'
import { memberProfile, ptofileMap } from '../lib/members'
import "animate.css"

export default{
  name: "Member",
  components: {
    AppHeader
  },
  data(){
    return{
      memberId: String,
      member: Object,
      profiles: [],
      color: "orange"
    }
  },
  created: function(){
    this.memberId = this.$route.params.id.toString();
    this.member = memberProfile[this.memberId];
    this.createProfile();

  },
  methods:{
    clickSNSLink(url){
      window.open(url);
    },
    createProfile(){
      this.color = this.member.color;
      this.createTitle();
      this.profiles = [];
      Object.keys(this.member.profile).forEach(key => {
        const msg = ptofileMap[key] + ": " + this.member.profile[key];
        this.profiles.push(msg)

      });
    },
    createTitle(){
      document.title = this.member.name + " : 酔いどれサーカス";
    }
  },
  watch: {
    $route(to){
      if(to.name == "Member"){
        this.memberId = to.params.id;
      }
    },
    memberId(){
      this.member = memberProfile[this.memberId];
      this.createProfile();
    }

  },
  computed: {
    // バインドするスタイルを生成
    styles () {
      return {
        '--color': this.color,
      }
    }
  }
}

</script>

<style scoped>
  h1{
    margin: 0;
  }

  #member-content{
    margin: 150px 0 0 0 ;
  }

  .icon{
    margin: auto;
    animation: fadeInUpBig;
     animation-duration: 1s;
  }

  #content{
    margin: 0 auto;
    gap: 15px;
    display: grid;
    grid-template-columns: 500px 1fr;
  }

  .intro{
    max-width: 950px;
    margin: 50px auto 0 0;
    word-break: keep-all;
    overflow-wrap: break-word;
  }

  .names{
    width: 300px;
    animation-delay: 0.5s;
    margin: 0 auto 0 0;
  }

  .names::before{
    content: '';
    position: absolute;
    left:0;
    top: 0;
    z-index: -1;
    height: 100%;
    background: var(--color);
    transform-origin: top right;
    animation-name: nameColorAnimation;
    animation-delay: 1s;
    animation-fill-mode:backwards;
    animation-duration:2s;
    animation-iteration-count: 1;
    animation-timing-function:ease;
    animation-direction:normal;
  }

  @keyframes nameColorAnimation{
    0%{
      width: 100%;
    }

    100%{
      width: 0%;
    }
  }


  .names{
    border-left: 25px solid var(--color);
    background-color: #fff;
  }
  .name{
    font-size: 300%;
    margin: 30px 0 0 0;
  }

  div.sns{
    margin: 30px auto 0 0;
    height: 30px;
    display: flex;
  }

  .sns{
    display: inline;
    font-size: 30px;
    padding: 0 10px;
    /* margin: 0 5px; */
  }

  .profile li{
    text-align: left;
  }

  /* タブレット縦 */
  @media screen and (max-width: 1024px){
    #member-content{
      margin: 0;
      display: inline-block;
    }

    #content{
      gap: 15px;
      display: grid;
      grid-template-columns: 1fr;
    }

    .names{
      margin: 0 auto 30px auto;
    }

    .name{
      margin: 0 auto;
    }

    div.sns{
      display: flex;
      margin: 30px 100px 50px auto;
    }
    .intro{
      max-width: 350px;
      margin: 0 auto;
      word-break: keep-all;
      overflow-wrap: break-word;
    }
    .icon{
      width: 80%;
      margin: 0 auto;
      animation: fadeInRight;
      animation-duration: 1s;
    }

    .profile{
      margin: 0 auto 0 auto;
    }
  }
</style>
